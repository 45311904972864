import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { theme } from 'shared/theme';

import Loader from 'components/loader/Loader';

import { RoutesConfig } from '../routes';

import 'shared/styles/fonts.css';
import 'shared/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

const router = createBrowserRouter(RoutesConfig);
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
});

const App = () => {
	return (
		<Suspense fallback={<Loader fullpage />}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<RouterProvider router={router} />
					<ToastContainer
						position="top-right"
						autoClose={3000}
						theme="dark"
						stacked
						pauseOnHover
						closeOnClick
						hideProgressBar={true}
						transition={Bounce}
					/>
				</ThemeProvider>
			</QueryClientProvider>
		</Suspense>
	);
};

export default App;
