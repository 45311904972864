import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import authStore from 'store/auth';
import { logout } from 'utils/logout';

export const AXIOS_INSTANCE = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

AXIOS_INSTANCE.interceptors.request.use(
	function (config) {
		const accessToken = authStore.getState().accessToken;
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

AXIOS_INSTANCE.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response) {
			switch (error.response.status) {
				case 401:
					logout();
					break;
				default:
					// eslint-disable-next-line no-console
					console.error('Request made and server responded with a non-pub status code:\n', error);
					break;
			}
		} else if (error.request) {
			// eslint-disable-next-line no-console
			console.error('Request made but no response received:\n', error);
		} else {
			// eslint-disable-next-line no-console
			console.error('An error occurred while setting up the request:\n', error);
		}

		return Promise.reject(error);
	},
);

export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const source = axios.CancelToken.source();
	const promise = AXIOS_INSTANCE({
		...config,
		...options,
		cancelToken: source.token,
	}).then(({ data }) => data);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled');
	};

	return promise;
};

export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
