import { FunctionComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useBasePath } from 'hooks/use-base-path';
import { Routes } from 'routes/consts';
import { useAuthStore } from 'store/auth';

interface Props {
	children: ReactElement;
}

export const AuthorisedLayout: FunctionComponent<Props> = ({ children }) => {
	const basePath = useBasePath();
	const { accessToken } = useAuthStore();

	if (!accessToken) {
		return <Navigate to={Routes.login} replace />;
	}

	if (basePath === Routes.root) {
		return <Navigate to={Routes.reports} replace />;
	}

	return <>{children}</>;
};

export default AuthorisedLayout;
