import { FC, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Menu, MenuItem, SvgIcon, Toolbar, Typography } from '@mui/material';
import { ReactComponent as MySvg } from 'assets/svg/combustion_logo.svg';
import { useResponsive } from 'hooks/use-responsive';
import { ColorPalette, theme } from 'shared/theme';
import { useAuthStore } from 'store/auth';
import { logout } from 'utils/logout';

type SidebarPropsType = {
	open: boolean;
	toggleDrawer: (val: boolean) => void;
};

const Header: FC<SidebarPropsType> = ({ open, toggleDrawer }) => {
	const isLargeScreen = useResponsive();
	const { user } = useAuthStore();
	const [anchor, setAnchor] = useState<null | HTMLElement>(null);

	const handleCloseMenu = () => {
		setAnchor(null);
	};
	const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchor(event.currentTarget);
	};

	return (
		<AppBar
			sx={{
				padding: 0,
				zIndex: isLargeScreen ? theme.zIndex.drawer + 1 : 1,
			}}
		>
			<Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					{open ? (
						<IconButton onClick={() => toggleDrawer(false)}>
							<ChevronLeftIcon />
						</IconButton>
					) : (
						<IconButton onClick={() => toggleDrawer(true)}>
							<MenuIcon />
						</IconButton>
					)}
					<SvgIcon component={MySvg} viewBox="0 0 362.25 69" sx={{ height: '40px', width: 'auto' }} />
				</Box>
				<IconButton onClick={handleOpenMenu} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
					<AccountCircleIcon fontSize="large" />
				</IconButton>
				<Menu
					open={Boolean(anchor)}
					anchorEl={anchor}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					onClose={handleCloseMenu}
				>
					<MenuItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} disabled>
						<Typography>
							{user?.firstName} {user?.lastName}
						</Typography>
						<Typography variant="caption">{user?.email}</Typography>
					</MenuItem>
					<MenuItem onClick={() => logout()}>
						<LogoutIcon sx={{ color: ColorPalette.red, marginRight: theme.spacing() }} fontSize="small" />
						Logout
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
