import { FC } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import { Drawer as MuiDrawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useResponsive } from 'hooks/use-responsive';
import { Routes } from 'routes/consts';
import { ColorPalette } from 'shared/theme';

import { DrawerHeader } from 'components/layouts/root-layout/RootLayout';

type SidebarPropsType = {
	open: boolean;
	toggleDrawer: (val: boolean) => void;
};

const Sidebar: FC<SidebarPropsType> = ({ open, toggleDrawer }) => {
	const isLargeScreen = useResponsive();

	const renderDrawerContent = () => (
		<List>
			{sidebarList.map((item) => (
				<ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
					<NavLink to={item.route}>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								px: 5,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'none',
									justifyContent: 'center',
								}}
							>
								{item.icon}
							</ListItemIcon>
							<ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					</NavLink>
				</ListItem>
			))}
		</List>
	);
	if (isLargeScreen) {
		return (
			<Drawer variant="permanent" open={open}>
				<DrawerHeader />
				{renderDrawerContent()}
			</Drawer>
		);
	}

	return (
		<SwipeableDrawer
			open={open}
			onClose={() => toggleDrawer(false)}
			onOpen={() => toggleDrawer(true)}
			sx={{
				'& .MuiPaper-root': {
					padding: 0,
					borderRadius: 0,
					width: '250px',
				},
			}}
		>
			{renderDrawerContent()}
		</SwipeableDrawer>
	);
};

export default Sidebar;

const sidebarList = [
	{ text: 'Home', route: Routes.home, icon: <HomeIcon /> },
	{ text: 'Reports', route: Routes.reports, icon: <DescriptionOutlinedIcon /> },
	{ text: 'Exploration', route: Routes.exploration, icon: <MapIcon /> },
	// { text: 'Subscriptions', route: Routes.subscriptions, icon: <ReceiptOutlinedIcon /> },
];

const openedMixin = (theme: Theme): CSSObject => ({
	width: 240,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	padding: 0,
	borderRadius: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
	padding: 0,
	borderRadius: 0,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(15)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(16)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	width: 240,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const NavLink = styled(RouterNavLink)`
	display: block;
	text-decoration: none;
	&.active {
		background-color: ${ColorPalette.white};
	}

	&.active svg {
		color: ${ColorPalette.black};
	}

	&.active .MuiTypography-root {
		color: ${ColorPalette.black};
		font-weight: 700;
	}
`;
