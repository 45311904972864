import { Box, SvgIcon } from '@mui/material';
import { ReactComponent as MySvg } from 'assets/svg/combustion_logo.svg';

type AuthContentLayoutPropsType = {
	children: React.ReactNode;
};

const AuthContentLayout = ({ children }: AuthContentLayoutPropsType) => {
	return (
		<Box
			sx={{
				backgroundSize: 'cover',
				height: '100vh',
				width: '100vw',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
			}}
		>
			<SvgIcon
				component={MySvg}
				viewBox="0 0 362.25 69"
				sx={{
					height: '50px',
					width: 'auto',
					alignSelf: 'flex-start',
					margin: 2,
				}}
			/>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingLeft: 4,
					paddingRight: 4,
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

export default AuthContentLayout;
