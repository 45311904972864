/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Combustion AI
 * Combustion AI API server
 * OpenAPI spec version: 0.0.1
 */
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseMutationResult,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
	AuthRestorePassVerificationReqDto,
	AuthRestorePasswordReqDto,
	AuthSignInReqDto,
	AuthSignInResDto,
	AuthSignUpReqDto,
	AuthSignUpVerificationReqDto,
	CommonServerErrorResDto,
	CommonSuccessResDto,
	ReportsCreateReqDto,
	ReportsGetFormData200,
	ReportsGetFormDataParams,
	ReportsGetListParams,
	ReportsGetListResDto,
	ReportsGetPoiSuggestionsParams,
} from '../model';
import type { BodyType, ErrorType } from '../mutator/custom-instance';
import { customInstance } from '../mutator/custom-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Checks if the server is alive and able to respond to requests.
 * @summary Health Check
 */
export const healthCheck = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<CommonSuccessResDto>({ url: `/health`, method: 'GET', signal }, options);
};

export const getHealthCheckQueryKey = () => {
	return [`/health`] as const;
};

export const getHealthCheckQueryOptions = <TData = Awaited<ReturnType<typeof healthCheck>>, TError = ErrorType<unknown>>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getHealthCheckQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheck>>> = ({ signal }) => healthCheck(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type HealthCheckQueryResult = NonNullable<Awaited<ReturnType<typeof healthCheck>>>;
export type HealthCheckQueryError = ErrorType<unknown>;

/**
 * @summary Health Check
 */
export const useHealthCheck = <TData = Awaited<ReturnType<typeof healthCheck>>, TError = ErrorType<unknown>>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof healthCheck>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getHealthCheckQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Authenticates a user and returns an access token.
 * @summary User Sign-In
 */
export const authSignIn = (authSignInReqDto: BodyType<AuthSignInReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<AuthSignInResDto>(
		{ url: `/auth/sign-in`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: authSignInReqDto },
		options,
	);
};

export const getAuthSignInMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authSignIn>>, TError, { data: BodyType<AuthSignInReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof authSignIn>>, TError, { data: BodyType<AuthSignInReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof authSignIn>>, { data: BodyType<AuthSignInReqDto> }> = (props) => {
		const { data } = props ?? {};

		return authSignIn(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthSignInMutationResult = NonNullable<Awaited<ReturnType<typeof authSignIn>>>;
export type AuthSignInMutationBody = BodyType<AuthSignInReqDto>;
export type AuthSignInMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary User Sign-In
 */
export const useAuthSignIn = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authSignIn>>, TError, { data: BodyType<AuthSignInReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authSignIn>>, TError, { data: BodyType<AuthSignInReqDto> }, TContext> => {
	const mutationOptions = getAuthSignInMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Registers a new user in the system.
 * @summary User Sign-Up
 */
export const authSignUp = (authSignUpReqDto: BodyType<AuthSignUpReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/auth/sign-up`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: authSignUpReqDto },
		options,
	);
};

export const getAuthSignUpMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authSignUp>>, TError, { data: BodyType<AuthSignUpReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof authSignUp>>, TError, { data: BodyType<AuthSignUpReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof authSignUp>>, { data: BodyType<AuthSignUpReqDto> }> = (props) => {
		const { data } = props ?? {};

		return authSignUp(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthSignUpMutationResult = NonNullable<Awaited<ReturnType<typeof authSignUp>>>;
export type AuthSignUpMutationBody = BodyType<AuthSignUpReqDto>;
export type AuthSignUpMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary User Sign-Up
 */
export const useAuthSignUp = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof authSignUp>>, TError, { data: BodyType<AuthSignUpReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authSignUp>>, TError, { data: BodyType<AuthSignUpReqDto> }, TContext> => {
	const mutationOptions = getAuthSignUpMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Verifies a new user email address.
 * @summary User Sign-Up Verification
 */
export const authSignUpVerification = (
	authSignUpVerificationReqDto: BodyType<AuthSignUpVerificationReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/auth/sign-up/verification`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: authSignUpVerificationReqDto,
		},
		options,
	);
};

export const getAuthSignUpVerificationMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authSignUpVerification>>,
		TError,
		{ data: BodyType<AuthSignUpVerificationReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof authSignUpVerification>>,
	TError,
	{ data: BodyType<AuthSignUpVerificationReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof authSignUpVerification>>,
		{ data: BodyType<AuthSignUpVerificationReqDto> }
	> = (props) => {
		const { data } = props ?? {};

		return authSignUpVerification(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthSignUpVerificationMutationResult = NonNullable<Awaited<ReturnType<typeof authSignUpVerification>>>;
export type AuthSignUpVerificationMutationBody = BodyType<AuthSignUpVerificationReqDto>;
export type AuthSignUpVerificationMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary User Sign-Up Verification
 */
export const useAuthSignUpVerification = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authSignUpVerification>>,
		TError,
		{ data: BodyType<AuthSignUpVerificationReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof authSignUpVerification>>,
	TError,
	{ data: BodyType<AuthSignUpVerificationReqDto> },
	TContext
> => {
	const mutationOptions = getAuthSignUpVerificationMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Sends a password restoration link to the user email address.
 * @summary Initiate Password Restoration
 */
export const authRestorePassword = (
	authRestorePasswordReqDto: BodyType<AuthRestorePasswordReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/auth/restore-password`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: authRestorePasswordReqDto },
		options,
	);
};

export const getAuthRestorePasswordMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authRestorePassword>>,
		TError,
		{ data: BodyType<AuthRestorePasswordReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof authRestorePassword>>,
	TError,
	{ data: BodyType<AuthRestorePasswordReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof authRestorePassword>>, { data: BodyType<AuthRestorePasswordReqDto> }> = (
		props,
	) => {
		const { data } = props ?? {};

		return authRestorePassword(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthRestorePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof authRestorePassword>>>;
export type AuthRestorePasswordMutationBody = BodyType<AuthRestorePasswordReqDto>;
export type AuthRestorePasswordMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Initiate Password Restoration
 */
export const useAuthRestorePassword = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authRestorePassword>>,
		TError,
		{ data: BodyType<AuthRestorePasswordReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof authRestorePassword>>, TError, { data: BodyType<AuthRestorePasswordReqDto> }, TContext> => {
	const mutationOptions = getAuthRestorePasswordMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Verifies the password restoration process using a token from the restoration link.
 * @summary Verify Password Restoration
 */
export const authRestorePasswordVerification = (
	authRestorePassVerificationReqDto: BodyType<AuthRestorePassVerificationReqDto>,
	options?: SecondParameter<typeof customInstance>,
) => {
	return customInstance<CommonSuccessResDto>(
		{
			url: `/auth/restore-password/verification`,
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			data: authRestorePassVerificationReqDto,
		},
		options,
	);
};

export const getAuthRestorePasswordVerificationMutationOptions = <
	TError = ErrorType<CommonServerErrorResDto>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authRestorePasswordVerification>>,
		TError,
		{ data: BodyType<AuthRestorePassVerificationReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof authRestorePasswordVerification>>,
	TError,
	{ data: BodyType<AuthRestorePassVerificationReqDto> },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof authRestorePasswordVerification>>,
		{ data: BodyType<AuthRestorePassVerificationReqDto> }
	> = (props) => {
		const { data } = props ?? {};

		return authRestorePasswordVerification(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthRestorePasswordVerificationMutationResult = NonNullable<Awaited<ReturnType<typeof authRestorePasswordVerification>>>;
export type AuthRestorePasswordVerificationMutationBody = BodyType<AuthRestorePassVerificationReqDto>;
export type AuthRestorePasswordVerificationMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Verify Password Restoration
 */
export const useAuthRestorePasswordVerification = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authRestorePasswordVerification>>,
		TError,
		{ data: BodyType<AuthRestorePassVerificationReqDto> },
		TContext
	>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
	Awaited<ReturnType<typeof authRestorePasswordVerification>>,
	TError,
	{ data: BodyType<AuthRestorePassVerificationReqDto> },
	TContext
> => {
	const mutationOptions = getAuthRestorePasswordVerificationMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Returns the current authenticated user data based on the provided JWT token.
 * @summary Get Current User
 */
export const usersGetMe = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<AuthSignInResDto>({ url: `/users/me`, method: 'GET', signal }, options);
};

export const getUsersGetMeQueryKey = () => {
	return [`/users/me`] as const;
};

export const getUsersGetMeQueryOptions = <
	TData = Awaited<ReturnType<typeof usersGetMe>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersGetMe>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getUsersGetMeQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof usersGetMe>>> = ({ signal }) => usersGetMe(requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof usersGetMe>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type UsersGetMeQueryResult = NonNullable<Awaited<ReturnType<typeof usersGetMe>>>;
export type UsersGetMeQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get Current User
 */
export const useUsersGetMe = <TData = Awaited<ReturnType<typeof usersGetMe>>, TError = ErrorType<CommonServerErrorResDto>>(options?: {
	query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof usersGetMe>>, TError, TData>>;
	request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getUsersGetMeQueryOptions(options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * This endpoint retrieves a list of reports for the authenticated user based on the provided query parameters.
 * @summary Get list of reports
 */
export const reportsGetList = (params?: ReportsGetListParams, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
	return customInstance<ReportsGetListResDto>({ url: `/reports`, method: 'GET', params, signal }, options);
};

export const getReportsGetListQueryKey = (params?: ReportsGetListParams) => {
	return [`/reports`, ...(params ? [params] : [])] as const;
};

export const getReportsGetListQueryOptions = <
	TData = Awaited<ReturnType<typeof reportsGetList>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params?: ReportsGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getReportsGetListQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsGetList>>> = ({ signal }) => reportsGetList(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof reportsGetList>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type ReportsGetListQueryResult = NonNullable<Awaited<ReturnType<typeof reportsGetList>>>;
export type ReportsGetListQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get list of reports
 */
export const useReportsGetList = <TData = Awaited<ReturnType<typeof reportsGetList>>, TError = ErrorType<CommonServerErrorResDto>>(
	params?: ReportsGetListParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsGetList>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getReportsGetListQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * This endpoint allows a user to create a new report by providing the necessary data.
 * @summary Create a new report
 */
export const reportsCreate = (reportsCreateReqDto: BodyType<ReportsCreateReqDto>, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>(
		{ url: `/reports`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: reportsCreateReqDto },
		options,
	);
};

export const getReportsCreateMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof reportsCreate>>, TError, { data: BodyType<ReportsCreateReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof reportsCreate>>, TError, { data: BodyType<ReportsCreateReqDto> }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsCreate>>, { data: BodyType<ReportsCreateReqDto> }> = (props) => {
		const { data } = props ?? {};

		return reportsCreate(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ReportsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof reportsCreate>>>;
export type ReportsCreateMutationBody = BodyType<ReportsCreateReqDto>;
export type ReportsCreateMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Create a new report
 */
export const useReportsCreate = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof reportsCreate>>, TError, { data: BodyType<ReportsCreateReqDto> }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof reportsCreate>>, TError, { data: BodyType<ReportsCreateReqDto> }, TContext> => {
	const mutationOptions = getReportsCreateMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * This endpoint allows the authenticated user to delete a report by its ID.
 * @summary Delete a report
 */
export const reportsDelete = (reportId: string, options?: SecondParameter<typeof customInstance>) => {
	return customInstance<CommonSuccessResDto>({ url: `/reports/${reportId}`, method: 'DELETE' }, options);
};

export const getReportsDeleteMutationOptions = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof reportsDelete>>, TError, { reportId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof reportsDelete>>, TError, { reportId: string }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportsDelete>>, { reportId: string }> = (props) => {
		const { reportId } = props ?? {};

		return reportsDelete(reportId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type ReportsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof reportsDelete>>>;

export type ReportsDeleteMutationError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Delete a report
 */
export const useReportsDelete = <TError = ErrorType<CommonServerErrorResDto>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof reportsDelete>>, TError, { reportId: string }, TContext>;
	request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof reportsDelete>>, TError, { reportId: string }, TContext> => {
	const mutationOptions = getReportsDeleteMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * This endpoint retrieves the necessary form data for creating reports.
 * @summary Get form data for reports
 */
export const reportsGetFormData = (
	params: ReportsGetFormDataParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<ReportsGetFormData200>({ url: `/reports/form-data`, method: 'GET', params, signal }, options);
};

export const getReportsGetFormDataQueryKey = (params: ReportsGetFormDataParams) => {
	return [`/reports/form-data`, ...(params ? [params] : [])] as const;
};

export const getReportsGetFormDataQueryOptions = <
	TData = Awaited<ReturnType<typeof reportsGetFormData>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: ReportsGetFormDataParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsGetFormData>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getReportsGetFormDataQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsGetFormData>>> = ({ signal }) =>
		reportsGetFormData(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof reportsGetFormData>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type ReportsGetFormDataQueryResult = NonNullable<Awaited<ReturnType<typeof reportsGetFormData>>>;
export type ReportsGetFormDataQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get form data for reports
 */
export const useReportsGetFormData = <TData = Awaited<ReturnType<typeof reportsGetFormData>>, TError = ErrorType<CommonServerErrorResDto>>(
	params: ReportsGetFormDataParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsGetFormData>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getReportsGetFormDataQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * This endpoint provides suggestions for Points of Interest (POI) based on the query parameters.
 * @summary Get POI suggestions
 */
export const reportsGetPoiSuggestions = (
	params: ReportsGetPoiSuggestionsParams,
	options?: SecondParameter<typeof customInstance>,
	signal?: AbortSignal,
) => {
	return customInstance<string[]>({ url: `/reports/poi-suggestions`, method: 'GET', params, signal }, options);
};

export const getReportsGetPoiSuggestionsQueryKey = (params: ReportsGetPoiSuggestionsParams) => {
	return [`/reports/poi-suggestions`, ...(params ? [params] : [])] as const;
};

export const getReportsGetPoiSuggestionsQueryOptions = <
	TData = Awaited<ReturnType<typeof reportsGetPoiSuggestions>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: ReportsGetPoiSuggestionsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsGetPoiSuggestions>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
) => {
	const { query: queryOptions, request: requestOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getReportsGetPoiSuggestionsQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof reportsGetPoiSuggestions>>> = ({ signal }) =>
		reportsGetPoiSuggestions(params, requestOptions, signal);

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<Awaited<ReturnType<typeof reportsGetPoiSuggestions>>, TError, TData> & {
		queryKey: QueryKey;
	};
};

export type ReportsGetPoiSuggestionsQueryResult = NonNullable<Awaited<ReturnType<typeof reportsGetPoiSuggestions>>>;
export type ReportsGetPoiSuggestionsQueryError = ErrorType<CommonServerErrorResDto>;

/**
 * @summary Get POI suggestions
 */
export const useReportsGetPoiSuggestions = <
	TData = Awaited<ReturnType<typeof reportsGetPoiSuggestions>>,
	TError = ErrorType<CommonServerErrorResDto>,
>(
	params: ReportsGetPoiSuggestionsParams,
	options?: {
		query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof reportsGetPoiSuggestions>>, TError, TData>>;
		request?: SecondParameter<typeof customInstance>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getReportsGetPoiSuggestionsQueryOptions(params, options);

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
