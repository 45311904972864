import { useEffect, useState } from 'react';
import { theme } from 'shared/theme';

export const useResponsive = () => {
	const breakpointValue = theme.breakpoints.values['sm'];

	const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(window.matchMedia(`(min-width: ${breakpointValue}px)`).matches);

	useEffect(() => {
		const mediaQuery = window.matchMedia(`(min-width: ${breakpointValue}px)`);
		const handleMediaChange = (event: MediaQueryListEvent) => {
			setIsAboveBreakpoint(event.matches);
		};

		mediaQuery.addEventListener('change', handleMediaChange);

		return () => {
			mediaQuery.removeEventListener('change', handleMediaChange);
		};
	}, []);

	return isAboveBreakpoint;
};
